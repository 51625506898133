/* eslint-disable max-len */
import { map, mergeLeft, pipe, propOr } from 'ramda'
import { ensureArray, keyValueArrToObj } from 'utils/fp'
import { tryJsonParse } from 'utils/misc'
import { trackApiMethodMetadata, getApiUrl } from 'api-client/helpers'
import ApiService from 'api-client/ApiService'
import {
  AlertManagerAlert,
  ClusterElement,
  GCluster,
  GetAddonVersions,
  GetCloudProvider,
  GetCluster,
  GetClusterClusterRoles,
  GetClusterDeployments,
  GetClusterDeploymentsItem,
  GetClusterKubeServices,
  GetClusterKubeServicesItem,
  GetClusterNamespaces,
  GetClusterNamespacesItem,
  GetClusterPods,
  GetClusterPodsItem,
  GetClusterRoles,
  GetKubernetesVersion,
  GetPrometheusAlertRules,
  GetPrometheusAlerts,
  GetPrometheusAlertsOverTime,
  IGenericClusterizedResponse,
  IGenericPayloadWithMetadata,
  IGenericResource,
  Node,
  SupportedRoleVersions,
} from './qbert.model'
import DataKeys from 'k8s/DataKeys'
import uuid from 'uuid'
import { createUrlWithQueryString } from 'core/plugins/route'
import {
  ClusterAgentsResponse,
  ImportedCluster,
} from 'app/plugins/infrastructure/components/importedClusters/model'
import {
  GetVirtualMachineDetails,
  GetVirtualMachines,
  IVirtualMachine,
} from 'app/plugins/kubevirt/components/virtual-machines/model'
import { convertVolumeTypeToApiParam } from 'app/plugins/kubevirt/components/virtual-machines/helpers'
import {
  APIResourcesResponse,
  IRbacAPIGroup,
  IRbacProfileDetails,
  APIGroupsResponse,
} from 'k8s/components/rbac/model'
import { ClusterProfilesResponse } from 'k8s/components/rbac/profiles/cluster-profile-model'
import {
  ClusterProfileBindingDetailsResponse,
  ClusterProfileBindingsResponse,
} from 'k8s/components/rbac/profiles/cluster-profile-binding-model'
import { ConfigMapsResponse } from 'k8s/components/config-maps/models'
import { IngressesResponse } from 'k8s/components/ingresses/model'
import { Pod } from 'k8s/components/pods/model'
import { PodMetricsResponse } from 'k8s/components/pods/metrics/model'
import { CSIDriver, CSIDriversResponse } from 'k8s/components/storage/csi-drivers/model'
import { PersistentVolumesResponse } from 'k8s/components/storage/persistent-volume/model'
import {
  StorageClassesItem,
  StorageClassesResponse,
} from 'k8s/components/storage/storage-classes/model'
import { EventsResponse } from 'app/plugins/infrastructure/components/clusters/model'
import { DataVolumesResponse } from 'k8s/components/storage/data-volumes/model'
import { NetworkAttachmentDefinitionsResponse } from 'app/plugins/kubevirt/components/virtual-machines/networks-model'
import { VirtualMachineInstancePresetsResponse } from 'app/plugins/kubevirt/components/virtual-machines/presets-model'
import {
  ClusterAddons,
  ClusterAddon,
} from 'app/plugins/infrastructure/components/clusters/cluster-addons/model'
import { K8SNodeResponse } from 'app/plugins/infrastructure/components/nodes/model'
import { StatefulSet, StatefulSetResponse } from 'k8s/components/stateful-sets/model'
import { ReplicaSetsResponse, ReplicaSetsItem } from 'k8s/components/replica-sets/model'
import { DaemonSet, GetDaemonSetsReponse } from 'k8s/components/daemon-sets/models'
import { JobItem, JobsResponse } from 'k8s/components/cronjobs/job-model'
import { CronjobItem, CronjobResponse } from 'k8s/components/cronjobs/cronjob-model'
import {
  AwsCloudProviderRegionDetails,
  AzureCloudProviderRegionDetails,
  CloudProviderDetailsResponse,
  CreateCloudProviderResponse,
} from 'app/plugins/infrastructure/components/cloudProviders/model'
import { PersistentVolumeClaimsResponse } from 'k8s/components/storage/persistent-volume-claims/model'
import { IAlertOverTime } from 'k8s/components/alarms/model'
import {
  GetVirtualMachineInstancesResponse,
  IVirtualMachineInstance,
} from 'app/plugins/kubevirt/components/virtual-machines/vmi-model'
import { GetCapiHostsResponse } from 'app/plugins/infrastructure/components/clusters/capi/details/overview/hosts/model'
import { LiveMigrationsResponse } from 'app/plugins/kubevirt/components/virtual-machines/live-migrations/model'
import {
  IInstanceType,
  InstanceTypesResponse,
} from 'app/plugins/kubevirt/components/virtual-machines/instance-types/instance-types-model'
import {
  ClusterInstanceTypesResponse,
  IClusterInstanceType,
} from 'app/plugins/kubevirt/components/virtual-machines/instance-types/cluster-instance-types-model'
import { IPAllocationsResponse } from 'app/plugins/kubevirt/components/virtual-machines/ip-allocations-model'

type AlertManagerRaw = Omit<AlertManagerAlert, 'clusterId' | 'id'>

// TODO: Fix these typings
const normalizeClusterizedResponse = <T>(
  clusterId: string,
  response: GCluster<T>,
): Array<IGenericClusterizedResponse<T>> =>
  pipe(
    propOr([], 'items'),
    map<any, any>(
      mergeLeft<any>({ clusterId }),
    ),
  )(response)

const normalizeClusterizedUpdate = (clusterId, response) => ({
  ...response,
  clusterId,
})

const normalizeImportedClusters = (apiResponse: GCluster<ImportedCluster>) => {
  const clusters = apiResponse.items
  const normalizedClusters = clusters.reduce((accum, cluster) => {
    const clusterName = cluster?.metadata?.name
    if (cluster?.spec?.displayName === 'do-not-delete-stub-eco-cluster') return accum
    accum.push({ ...cluster, uuid: clusterName })
    return accum
  }, [])
  return normalizedClusters
}

/* eslint-disable camelcase */
class Qbert extends ApiService {
  public getClassName() {
    return 'qbert'
  }

  static apiMethodsMetadata = []

  // cachedEndpoint = ''

  protected async getEndpoint() {
    const endpoint = await this.client.keystone.getServiceEndpoint('qbert', 'admin')
    const mappedEndpoint = endpoint?.replace(/v(1|2|3)$/, `v3`)

    // Certain operations like column renderers from ListTable need to prepend the Qbert URL to links
    // sent from the backend.  But getting the endpoint is an async operation so we need to make an
    // sync version.  In theory this should always be set since keystone must get the service
    // catalog before any Qbert API calls are made.
    // this.cachedEndpoint = mappedEndpoint
    return mappedEndpoint
  }

  scopedEnpointPath = () => this.client.activeProjectId

  monocularBaseUrl = async () => {
    return this.client.keystone.getServiceEndpoint('monocular', 'public')
  }

  clusterBaseUrl = (clusterId) => `/clusters/${clusterId}/k8sapi/api/v1`

  // clusterMonocularBaseUrl = (clusterId, version = 'v1') =>
  //   pathJoin(
  //     this.clusterBaseUrl(clusterId),
  //     'namespaces',
  //     'kube-system',
  //     'services',
  //     'monocular-api-svc:80',
  //     'proxy',
  //     version || '/',
  //   )

  /* Cloud Providers */
  @trackApiMethodMetadata({ url: '/cloudProviders', type: 'GET' })
  getCloudProviders = async () => {
    const url = `/cloudProviders`
    return this.client.basicGet<GetCloudProvider[]>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCloudProviders',
      },
    })
  }

  @trackApiMethodMetadata({ url: '/cloudProviders', type: 'POST', disable: true })
  createCloudProvider = async (body) => {
    const url = `/cloudProviders`
    const { uuid } = await this.client.basicPost<CreateCloudProviderResponse>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createCloudProvider',
      },
    })
    const cloudProviders = await this.getCloudProviders()
    return cloudProviders.find((cp) => cp.uuid === uuid)
  }

  @trackApiMethodMetadata({
    url: '/cloudProviders/:cloudProviderId',
    type: 'GET',
    params: ['cloudProviderId'],
  })
  getCloudProviderDetails = async (cpId) => {
    const url = `/cloudProviders/${cpId}`
    return this.client.basicGet<CloudProviderDetailsResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCloudProviderDetails',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/cloudProviders/:cloudProviderId/region/:regionName',
    type: 'GET',
    params: ['cloudProviderId', 'regionName'],
  })
  getCloudProviderRegionDetails = async (cpId, regionId) => {
    const url = `/cloudProviders/${cpId}/region/${regionId}`
    return this.client.basicGet<AwsCloudProviderRegionDetails | AzureCloudProviderRegionDetails>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCloudProviderRegionDetails',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/cloudProviders/:cloudProviderId',
    type: 'PUT',
    params: ['cloudProviderId'],
  })
  updateCloudProvider = async (cpId, body) => {
    const url = `/cloudProviders/${cpId}`
    return this.client.basicPut({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateCloudProvider',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/cloudProviders/:cloudProviderId',
    type: 'DELETE',
    params: ['cloudProviderId'],
  })
  deleteCloudProvider = async (cpId) => {
    const url = `/cloudProviders/${cpId}`
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteCloudProvider',
      },
    })
  };

  [DataKeys.CloudProviders] = {
    create: this.createCloudProvider.bind(this),
    list: this.getCloudProviders.bind(this),
    details: this.getCloudProviderDetails.bind(this),
    regionDetails: this.getCloudProviderDetails.bind(this),
    update: this.updateCloudProvider.bind(this),
    delete: this.deleteCloudProvider.bind(this),
  }

  /* Cloud Providers Types */
  @trackApiMethodMetadata({ url: '/cloudProvider/types', type: 'GET' })
  getCloudProviderTypes = async () => {
    const url = `/cloudProvider/types`
    return this.client.basicGet({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCloudProviderTypes',
      },
    })
  }

  /* Node Pools */
  @trackApiMethodMetadata({ url: '/nodePools', type: 'GET' })
  getNodePools = async () => {
    const url = `/nodePools`
    return this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getNodePools',
      },
    })
  }

  /* Nodes */
  @trackApiMethodMetadata({ url: '/nodes', type: 'GET' })
  getNodes = async () => {
    const url = `/nodes`
    return this.client.basicGet<Node[]>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getNodes',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/nodes',
    type: 'GET',
    params: ['clusterId'],
  })
  getK8sNodes = async (clusterId) => {
    const url = getApiUrl(`api/v1/nodes`, clusterId)
    const data = await this.client.basicGet<K8SNodeResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getK8sNodes',
      },
    })
    return data?.items?.map(this.convertResource(clusterId)) || []
  };

  [DataKeys.Nodes] = {
    list: this.getNodes,
  }

  /* SSH Keys */
  @trackApiMethodMetadata({
    url: '/cloudProviders/:cloudProviderId/region/:regionName',
    type: 'PUT',
    params: ['cloudProviderId', 'regionName'],
  })
  importSshKey = async (cpId, regionId, body) => {
    const url = `/cloudProviders/${cpId}/region/${regionId}`
    return this.client.basicPost({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'importSshKey',
      },
    })
  }

  /* Clusters */
  @trackApiMethodMetadata({ url: '/clusters', type: 'GET' })
  getClusters = async () => {
    const url = `/clusters`
    return this.client.basicGet<ClusterElement[]>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusters',
      },
    })
  }

  getSunpikeApis = async () => {
    const url = `/sunpike/apis/sunpike.platform9.com`
    return this.client.basicGet<any>({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusters',
      },
    })
  }

  getImportedClusters = async () => {
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/clusters`
    const response = await this.client.basicGet<GCluster<ImportedCluster>>({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusters',
      },
    })
    return normalizeImportedClusters(response)
  }

  @trackApiMethodMetadata({ url: '/clusters/:clusterId', type: 'GET', params: ['clusterId'] })
  getClusterDetails = async (clusterId) => {
    const url = `/clusters/${clusterId}`
    return this.client.basicGet<ClusterElement>({
      url,
      // version: 'v4', // TODO update to v4 when backend releases 5.1
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterDetails',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/storage.k8s.io/v1/csidrivers',
    type: 'GET',
    params: ['clusterId'],
  })
  getCSIDrivers = async (clusterId) => {
    const url = getApiUrl(`apis/storage.k8s.io/v1/csidrivers`, clusterId)
    const data = await this.client.basicGet<CSIDriversResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCSIDrivers',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/storage.k8s.io/v1/csidrivers/:name',
    type: 'DELETE',
    params: ['clusterId', 'name'],
    error: { isClusterError: true, k8sResource: 'CSI Driver' },
  })
  deleteCSIDriver = async (clusterId, name) => {
    const url = getApiUrl(`apis/storage.k8s.io/v1/csidrivers/${name}`, clusterId)
    const data = await this.client.basicDelete<CSIDriver>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteCSIDriver',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/persistentvolumes',
    type: 'GET',
    params: ['clusterId'],
  })
  getPersistentVolumes = async (clusterId) => {
    const url = getApiUrl(`api/v1/persistentvolumes`, clusterId)
    const data = await this.client.basicGet<PersistentVolumesResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPersistentVolumes',
      },
    })
    return data.items && data.items.map(this.convertResource(clusterId))
  }

  getK8sSupportedRoleVersions = async () => {
    const url = '/clusters/supportedRoleVersions'
    const supportedRoleVersions = await this.client.basicGet<SupportedRoleVersions>({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterDetails',
      },
    })
    return supportedRoleVersions
  }

  createCluster = async (body) => {
    // Note: This API response only returns new `uuid` in the response.
    // You might want to do a GET afterwards if you need any of the cluster information.
    const url = `/clusters`
    return this.client.basicPost<ClusterElement>({
      url,
      version: 'v4',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createCluster',
      },
    })
  }

  @trackApiMethodMetadata({ url: '/clusters/:clusterId', type: 'PUT', params: ['clusterId'] })
  updateCluster = async (clusterId, body) => {
    const url = `/clusters/${clusterId}`
    await this.client.basicPut<ClusterElement>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateCluster',
      },
    })
    const clusters = await this.getClusters()
    return clusters?.find((cluster) => cluster.uuid === clusterId) || ({} as ClusterElement)
  }

  upgradeClusterNodes = async (clusterId, type, body = null) => {
    const url = createUrlWithQueryString(`/clusters/${clusterId}/upgrade?type=${type}`)
    return this.client.basicPost<ClusterElement>({
      url,
      version: 'v4',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'upgradeClusterNodes',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId',
    type: 'DELETE',
    params: ['clusterId'],
  })
  deleteCluster = async (clusterId) => {
    const url = `/clusters/${clusterId}`
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteCluster',
      },
    })
  };

  [DataKeys.Clusters] = {
    list: this.getClusters,
  }

  // @param clusterId = cluster.uuid
  // @param nodes = [{ uuid: node.uuid, isMaster: (true|false) }]
  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/attach',
    type: 'POST',
    params: ['clusterId'],
  })
  attachNodes = async (clusterId, body) => {
    const url = `/clusters/${clusterId}/attach`
    return this.client.basicPost({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'attachNodes',
      },
    })
  }

  // @param clusterId = cluster.uuid
  // @param nodes = [node1Uuid, node2Uuid, ...]
  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/detach',
    type: 'POST',
    params: ['clusterId'],
  })
  detachNodes = async (clusterId, nodeUuids) => {
    const body = nodeUuids.map((nodeUuid) => ({ uuid: nodeUuid }))
    const url = `/clusters/${clusterId}/detach`
    return this.client.basicPost({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'detachNodes',
      },
    })
  }

  @trackApiMethodMetadata({ url: '/webcli/:clusterId', type: 'POST', params: ['clusterId'] })
  getCliToken = async (clusterId, namespace) => {
    const url = `/webcli/${clusterId}`
    const response = await this.client.basicPost<any>({
      url,
      body: {
        namespace,
      },
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCliToken',
      },
    })
    return response.token
  }

  @trackApiMethodMetadata({
    url: '/kubeconfig/:clusterId',
    type: 'GET',
    params: ['clusterId'],
  })
  getKubeConfig = async (clusterId) => {
    const url = `/kubeconfig/${clusterId}`
    return this.client.basicGet<string>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getKubeConfig',
      },
    })
  }

  discoverExternalClusters = async (body) => {
    const url = '/externalClusters/discover'
    const discoveredClusters = await this.client.basicPost<any>({
      url,
      version: 'v4',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'discoverExternalClusters',
      },
    })
    return discoveredClusters
  }

  @trackApiMethodMetadata({
    url: '/externalClusters/register',
    type: 'POST',
  })
  registerExternalCluster = async (body) => {
    const url = '/externalClusters/register'
    const registeredCluster = await this.client.basicPost<any>({
      url,
      version: 'v4',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'registerExternalCluster',
      },
    })
    return registeredCluster
  }

  deregisterExternalCluster = async (id) => {
    const url = `/externalClusters/${id}/deregister`
    return this.client.basicPost({
      url,
      body: {},
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'deregisterExternalCluster',
      },
    })
  }

  /* k8s API */
  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/version',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'K8s Version' },
  })
  getKubernetesVersion = async (clusterId) => {
    const url = getApiUrl(`version`, clusterId)
    return this.client.basicGet<GetKubernetesVersion>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getKubernetesVersion',
      },
    })
  }

  /* k8s API */
  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/addonversions',
    version: 'v4',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Addon Versions' },
  })
  getAddonVersions = async (clusterId) => {
    const url = `/clusters/${clusterId}/addonversions`
    const addonVersions = await this.client.basicGet<GetAddonVersions>({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getAddonVersions',
      },
    })
    // TODO: Update this with a GetOneAction which  will handle an object response
    // Here, converted the single object to an array as the current useListAction is only meant to work with array responses.
    return ensureArray(addonVersions)
  }

  convertResource = (clusterId) => <T extends IGenericPayloadWithMetadata>(
    item: T,
  ): IGenericResource<T> => ({
    ...item,
    clusterId,
    name: item.metadata.name,
    created: item.metadata.creationTimestamp,
    id: item.metadata.uid,
    ...(item.metadata.namespace ? { namespace: item.metadata.namespace } : {}),
  })

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Namespace' },
  })
  getClusterNamespaces = async (clusterId) => {
    const url = getApiUrl(`api/v1/namespaces`, clusterId)
    const data = await this.client.basicGet<GetClusterNamespaces>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterNamespaces',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces',
    type: 'POST',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Namespace' },
  })
  createNamespace = async (clusterId, body) => {
    const url = getApiUrl(`api/v1/namespaces`, clusterId)
    const raw = await this.client.basicPost<GetClusterNamespacesItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createNamespace',
      },
    })
    return this.convertResource(clusterId)(raw)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace',
    type: 'DELETE',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'Namespace' },
  })
  deleteNamespace = async (clusterId, namespaceName) => {
    const url = getApiUrl(`api/v1/namespaces/${namespaceName}`, clusterId)
    const data = await this.client.basicDelete<GetClusterNamespacesItem>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteNamespace',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/pods',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Pod' },
  })
  getClusterPods = async (clusterId: string, namespace?: string) => {
    const url = getApiUrl(
      namespace ? `api/v1/namespaces/${namespace}/pods` : `api/v1/pods`,
      clusterId,
    )
    const data = await this.client.basicGet<GetClusterPods>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterPods',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/pods/:name',
    type: 'GET',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'Pod' },
  })
  getClusterPod = async (clusterId, namespace, name) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/pods/${name}`, clusterId)
    const data = await this.client.basicGet<Pod>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterPod',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/deployments',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Deployment' },
  })
  getClusterDeployments = async (clusterId: string, namespace?: string) => {
    const url = getApiUrl(
      namespace ? `apis/apps/v1/namespaces/${namespace}/deployments` : `apis/apps/v1/deployments`,
      clusterId,
    )
    const data = await this.client.basicGet<GetClusterDeployments>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterDeployments',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/deployments/:name',
    type: 'GET',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'Deployment' },
  })
  getClusterDeployment = async (clusterId, namespace, name) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/deployments/${name}`, clusterId)
    const data = await this.client.basicGet<GetClusterDeploymentsItem>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterDeployment',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/k8sapi/apis/apps/v1/namespaces/:namespace/deployments/:deployment',
    type: 'DELETE',
    params: ['namespace', 'deployment'],
    error: { isClusterError: true, k8sResource: 'Deployment' },
  })
  deleteDeployment = async (clusterId, namespace, name) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/deployments/${name}`, clusterId)
    const data = await this.client.basicDelete<GetClusterDeploymentsItem>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteDeployment',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/services',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Service' },
  })
  getClusterKubeServices = async (clusterId, namespace) => {
    const url = getApiUrl(
      namespace ? `api/v1/namespaces/${namespace}/services` : `api/v1/services`,
      clusterId,
    )
    const data = await this.client.basicGet<GetClusterKubeServices>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterKubeServices',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/services/:service',
    type: 'GET',
    params: ['clusterId', 'namespace', 'service'],
  })
  getClusterKubeService = async (clusterId, namespace, service) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/services/${service}`, clusterId)
    const data = await this.client.basicGet<GetClusterKubeServicesItem>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterKubeService',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/services/:name',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name'],
  })
  updateClusterKubeService = async (clusterId, namespace, name, body) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/services/${name}`, clusterId)
    const data = await this.client.basicPut<GetClusterKubeServicesItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateClusterKubeService',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/networking.k8s.io/v1/ingresses',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Ingress' },
  })
  getIngresses = async (clusterId) => {
    const url = getApiUrl(`apis/networking.k8s.io/v1/ingresses`, clusterId)
    const data = await this.client.basicGet<IngressesResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getIngresses',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/storage.k8s.io/v1/storageclasses/:name',
    type: 'GET',
    params: ['clusterId', 'name'],
    error: { isClusterError: true, k8sResource: 'StorageClass' },
  })
  getClusterStorageClassByName = async (clusterId, name) => {
    const url = getApiUrl(`apis/storage.k8s.io/v1/storageclasses/${name}`, clusterId)
    const data = await this.client.basicGet<StorageClassesResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterStorageClassByName',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/storage.k8s.io/v1/storageclasses',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'StorageClass' },
  })
  getClusterStorageClasses = async (clusterId) => {
    const url = getApiUrl(`apis/storage.k8s.io/v1/storageclasses`, clusterId)
    const data = await this.client.basicGet<StorageClassesResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterStorageClasses',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/storage.k8s.io/v1/storageclasses/:name',
    type: 'PUT',
    params: ['clusterId', 'name'],
    error: { isClusterError: true, k8sResource: 'StorageClass' },
  })
  updateClusterStorageClass = async (clusterId, name, body) => {
    const url = getApiUrl(`apis/storage.k8s.io/v1/storageclasses/${name}`, clusterId)
    const data = await this.client.basicPut<StorageClassesItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateClusterStorageClasses',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/storage.k8s.io/v1/storageclasses',
    type: 'POST',
    params: ['clusterId'],
    error: {
      isClusterError: true,
      k8sResource: 'StorageClass',
      k8sResourceAccessor: 'params.name',
    },
  })
  createStorageClass = async (clusterId, body) => {
    const url = getApiUrl(`apis/storage.k8s.io/v1/storageclasses`, clusterId)
    const data = await this.client.basicPost<StorageClassesItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createStorageClass',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/storage.k8s.io/v1/storageclasses/:storageClass',
    type: 'DELETE',
    params: ['clusterId', 'storageClass'],
    error: { isClusterError: true, k8sResource: 'StorageClass' },
  })
  deleteStorageClass = async (clusterId, name) => {
    const url = getApiUrl(`apis/storage.k8s.io/v1/storageclasses/${name}`, clusterId)
    const data = await this.client.basicDelete<StorageClassesItem>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteStorageClass',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/virtualmachineinstances',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'VirtualMachineInstance' },
  })
  getVirtualMachineInstances = async (clusterId) => {
    const url = getApiUrl(`apis/kubevirt.io/v1/virtualmachineinstances`, clusterId)
    const data = await this.client.basicGet<GetVirtualMachineInstancesResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getVirtualMachineInstances',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/virtualmachines',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'VirtualMachine' },
  })
  getVirtualMachines = async (clusterId) => {
    const url = getApiUrl(`apis/kubevirt.io/v1/virtualmachines`, clusterId)
    const data = await this.client.basicGet<GetVirtualMachines>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getVirtualMachines',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachines/:name',
    type: 'GET',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'VirtualMachine' },
  })
  getVirtualMachineByName = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachines/${name}`,
      clusterId,
    )
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getVirtualMachineByName',
      },
    })
    return data
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachineinstances/:name',
    type: 'GET',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'VirtualMachineInstance' },
  })
  getVirtualMachineInstanceByName = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachineinstances/${name}`,
      clusterId,
    )
    const data = await this.client.basicGet<GetVirtualMachineDetails>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getVirtualMachineInstanceByName',
      },
    })
    return data
  }

  getVirtualMachineVolumeDetails = async (clusterId, namespace, volumeType, name) => {
    // cdi.kubevirt.io/v1beta1/namespaces/default/datavolumes/dv-rootfs
    const url = getApiUrl(
      `apis/cdi.kubevirt.io/v1beta1/namespaces/${namespace}/${convertVolumeTypeToApiParam(
        volumeType,
      )}/${name}`,
      clusterId,
    )
    const data = await this.client.basicGet({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getVirtualMachineVolumeDetails',
      },
    })
    return data
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachineinstances',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'VirtualMachineInstance' },
  })
  createVirtualMachineInstance = async (clusterId, namespace, body) => {
    const url = getApiUrl(
      `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachineinstances`,
      clusterId,
    )
    const data = await this.client.basicPost<IVirtualMachineInstance>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createVirtualMachineInstance',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachines/:name',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'VirtualMachine' },
  })
  updateVirtualMachine = async ({ clusterId, namespace, name, body }) => {
    const url = getApiUrl(
      `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachines/${name}`,
      clusterId,
    )
    const data = await this.client.basicPut<IVirtualMachine>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateVirtualMachine',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachines/:name',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'VirtualMachine' },
  })
  patchVirtualMachine = async ({
    clusterId,
    namespace,
    name,
    body,
    contentType = 'application/merge-patch+json',
  }) => {
    const url = getApiUrl(
      `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachines/${name}`,
      clusterId,
    )
    const data = await this.client.basicPatch<IVirtualMachine>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchVirtualMachine',
        config: {
          headers: {
            'Content-Type': contentType,
          },
        },
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachineinstances/:name',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'VirtualMachineInstance' },
  })
  updateVirtualMachineInstance = async ({ clusterId, namespace, name, body }) => {
    const url = getApiUrl(
      `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachineinstances/${name}`,
      clusterId,
    )
    const data = await this.client.basicPut<IVirtualMachineInstance>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateVirtualMachineInstance',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachineinstances/:name',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'VirtualMachineInstance' },
  })
  patchVirtualMachineInstance = async ({
    clusterId,
    namespace,
    name,
    body,
    contentType = 'application/merge-patch+json',
  }) => {
    const url = getApiUrl(
      `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachineinstances/${name}`,
      clusterId,
    )
    const data = await this.client.basicPatch<IVirtualMachineInstance>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchVirtualMachineInstance',
        config: {
          headers: {
            'Content-Type': contentType,
          },
        },
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachineinstances/:name',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'VirtualMachineInstance' },
  })
  deleteVirtualMachineInstance = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachineinstances/${name}`,
      clusterId,
    )
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteVirtualMachineInstance',
      },
    })
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachines/:name',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'VirtualMachine' },
  })
  deleteVirtualMachine = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachines/${name}`,
      clusterId,
    )
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteVirtualMachine',
      },
    })
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/subresources.kubevirt.io/v1/namespaces/:namespace/virtualmachineinstances/:name/:operation',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name', 'operation'],
    error: { isClusterError: true, k8sResource: 'VirtualMachineInstance' },
  })
  vmiPowerOperation = async (clusterId, namespace, name, operation) => {
    const url = getApiUrl(
      `apis/subresources.kubevirt.io/v1/namespaces/${namespace}/virtualmachineinstances/${name}/${operation}`,
      clusterId,
    )
    return this.client.basicPut<IVirtualMachineInstance>({
      url,
      body: {},
      options: {
        clsName: this.getClassName(),
        mthdName: 'vmiPowerOperation',
      },
    })
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/subresources.kubevirt.io/v1/namespaces/:namespace/virtualmachines/:name/:operation',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name', 'operation'],
    error: { isClusterError: true, k8sResource: 'VirtualMachine' },
  })
  virtualMachinePowerOperation = async (clusterId, namespace, name, operation) => {
    const url = getApiUrl(
      `apis/subresources.kubevirt.io/v1/namespaces/${namespace}/virtualmachines/${name}/${operation}`,
      clusterId,
    )
    return this.client.basicPut<IVirtualMachine>({
      url,
      body: {},
      options: {
        clsName: this.getClassName(),
        mthdName: 'virtualMachinePowerOperation',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/virtualmachineinstancemigrations',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'LiveMigration' },
  })
  getLiveMigrations = async (clusterId) => {
    const url = getApiUrl(`apis/kubevirt.io/v1/virtualmachineinstancemigrations`, clusterId)
    const data = await this.client.basicGet<LiveMigrationsResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getLiveMigrations',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachineinstancemigrations',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'VirtualMachineInstance' },
  })
  virtualMachineInstanceMigration = async (clusterId, namespace, body) => {
    const vmiName = body?.spec?.vmiName
    const url = getApiUrl(
      `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachineinstancemigrations`,
      clusterId,
    )
    await this.client.basicPost<unknown>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'virtualMachineInstanceMigration',
      },
    })
    // Want to return the VMI object to update the cache
    const data = await this.client.basicGet<IVirtualMachineInstance>({
      url: getApiUrl(
        `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachineinstances/${vmiName}`,
        clusterId,
      ),
      options: {
        clsName: this.getClassName(),
        mthdName: 'getVirtualMachineInstanceByName',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/kubevirt.io/v1/namespaces/:namespace/virtualmachines',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'VirtualMachine' },
  })
  createVirtualMachine = async (clusterId, namespace, body) => {
    const url = getApiUrl(`apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachines`, clusterId)
    const data = await this.client.basicPost<IVirtualMachine>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createVirtualMachine',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/instancetype.kubevirt.io/v1alpha2/virtualmachineinstancetypes',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'InstanceType' },
  })
  getInstanceTypes = async (clusterId) => {
    const url = getApiUrl(
      `apis/instancetype.kubevirt.io/v1alpha2/virtualmachineinstancetypes`,
      clusterId,
    )
    const data = await this.client.basicGet<InstanceTypesResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getInstanceTypes',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/instancetype.kubevirt.io/v1alpha2/namespaces/:namespace/virtualmachineinstancetypes',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'InstanceType' },
  })
  createInstanceType = async (clusterId, namespace, body) => {
    const url = getApiUrl(
      `apis/instancetype.kubevirt.io/v1alpha2/namespaces/${namespace}/virtualmachineinstancetypes`,
      clusterId,
    )
    const data = await this.client.basicPost<IInstanceType>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createInstanceType',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/instancetype.kubevirt.io/v1alpha2/namespaces/:namespace/virtualmachineinstancetypes/:name',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'InstanceType' },
  })
  updateInstanceType = async ({ clusterId, namespace, name, body }) => {
    const url = getApiUrl(
      `apis/instancetype.kubevirt.io/v1alpha2/namespaces/${namespace}/virtualmachineinstancetypes/${name}`,
      clusterId,
    )
    const data = await this.client.basicPut<IInstanceType>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateInstanceType',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/instancetype.kubevirt.io/v1alpha2/namespaces/:namespace/virtualmachineinstancetypes/:name',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'InstanceType' },
  })
  deleteInstanceType = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/instancetype.kubevirt.io/v1alpha2/namespaces/${namespace}/virtualmachineinstancetypes/${name}`,
      clusterId,
    )
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteInstanceType',
      },
    })
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/instancetype.kubevirt.io/v1alpha2/virtualmachineclusterinstancetypes',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'ClusterInstanceType' },
  })
  getClusterInstanceTypes = async (clusterId) => {
    const url = getApiUrl(
      `apis/instancetype.kubevirt.io/v1alpha2/virtualmachineclusterinstancetypes`,
      clusterId,
    )
    const data = await this.client.basicGet<ClusterInstanceTypesResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterInstanceTypes',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/instancetype.kubevirt.io/v1alpha2/virtualmachineclusterinstancetypes',
    type: 'POST',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'ClusterInstanceType' },
  })
  createClusterInstanceType = async (clusterId, body) => {
    const url = getApiUrl(
      `apis/instancetype.kubevirt.io/v1alpha2/virtualmachineclusterinstancetypes`,
      clusterId,
    )
    const data = await this.client.basicPost<IClusterInstanceType>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createClusterInstanceType',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/instancetype.kubevirt.io/v1alpha2/virtualmachineclusterinstancetypes/:name',
    type: 'PUT',
    params: ['clusterId', 'name'],
    error: { isClusterError: true, k8sResource: 'ClusterInstanceType' },
  })
  updateClusterInstanceType = async ({ clusterId, name, body }) => {
    const url = getApiUrl(
      `apis/instancetype.kubevirt.io/v1alpha2/virtualmachineclusterinstancetypes/${name}`,
      clusterId,
    )
    const data = await this.client.basicPut<IClusterInstanceType>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateClusterInstanceType',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/instancetype.kubevirt.io/v1alpha2/virtualmachineclusterinstancetypes/:name',
    type: 'DELETE',
    params: ['clusterId', 'name'],
    error: { isClusterError: true, k8sResource: 'ClusterInstanceType' },
  })
  deleteClusterInstanceType = async (clusterId, name) => {
    const url = getApiUrl(
      `apis/instancetype.kubevirt.io/v1alpha2/virtualmachineclusterinstancetypes/${name}`,
      clusterId,
    )
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteClusterInstanceType',
      },
    })
  }

  getVirtualMachinePresets = async (clusterId, namespace) => {
    const url = getApiUrl(
      `apis/kubevirt.io/v1/namespaces/${namespace}/virtualmachineinstancepresets`,
      clusterId,
    )
    const data = await this.client.basicGet<VirtualMachineInstancePresetsResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getVirtualMachinePresets',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  getNetworks = async (clusterId, namespace) => {
    const url = getApiUrl(
      `apis/k8s.cni.cncf.io/v1/namespaces/${namespace}/network-attachment-definitions`,
      clusterId,
    )
    const data = await this.client.basicGet<NetworkAttachmentDefinitionsResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getNetworks',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  getIpAllocations = async (clusterId) => {
    const url = getApiUrl(`apis/dhcp.plumber.k8s.pf9.io/v1alpha1/ipallocations`, clusterId)
    const data = await this.client.basicGet<IPAllocationsResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getIpAllocations',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  getDataVolumes = async (clusterId) => {
    const url = getApiUrl(`apis/cdi.kubevirt.io/v1beta1/datavolumes`, clusterId)
    const data = await this.client.basicGet<DataVolumesResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getDataVolumes',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/pods',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: {
      isClusterError: true,
      k8sResource: 'Pod',
      k8sResourceAccessor: 'response.config.data.metadata.name',
    },
  })
  createPod = async (clusterId, namespace, body) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/pods`, clusterId)
    const data = await this.client.basicPost<GetClusterPodsItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createPod',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/pods/:name',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name'],
    error: {
      isClusterError: true,
      k8sResource: 'Pod',
    },
  })
  updatePod = async ({ clusterId, namespace, name, body }) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/pods/${name}`, clusterId)
    const data = await this.client.basicPut<GetClusterPodsItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updatePod',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/pods/:name',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'name'],
    error: {
      isClusterError: true,
      k8sResource: 'Pod',
    },
  })
  patchPod = async ({
    clusterId,
    namespace,
    name,
    body,
    contentType = 'application/merge-patch+json',
  }) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/pods/${name}`, clusterId)
    const data = await this.client.basicPatch<GetClusterPodsItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchPod',
        config: {
          headers: {
            'Content-Type': contentType,
          },
        },
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/pods/:podName',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'podName'],
    error: { isClusterError: true, k8sResource: 'Pod', k8sResourceAccessor: 'params.name' },
  })
  deletePod = async (clusterId, namespace, name) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/pods/${name}`, clusterId)
    const data = await this.client.basicDelete<GetClusterPodsItem>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deletePod',
      },
    })
    return this.convertResource(clusterId)(data)
  };

  [DataKeys.Pods] = {
    create: this.createPod.bind(this),
    list: this.getClusterPods.bind(this),
    delete: this.deletePod.bind(this),
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/deployments',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: {
      isClusterError: true,
      k8sResource: 'Deployment',
      k8sResourceAccessor: 'response.config.data.metadata.name',
    },
  })
  createDeployment = async (clusterId, namespace, body) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/deployments`, clusterId)
    const data = await this.client.basicPost<GetClusterDeploymentsItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createDeployment',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/deployments/:name',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name'],
  })
  updateDeployment = async (clusterId, namespace, name, body) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/deployments/${name}`, clusterId)
    const data = await this.client.basicPut<GetClusterDeploymentsItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateDeployment',
      },
    })
    return this.convertResource(clusterId)(data)
  };

  [DataKeys.Deployments] = {
    create: this.createDeployment.bind(this),
    list: this.getClusterDeployments.bind(this),
    delete: this.deleteDeployment.bind(this),
    update: this.updateDeployment.bind(this),
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/services',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: {
      isClusterError: true,
      k8sResource: 'Service',
      k8sResourceAccessor: 'response.config.data.metadata.name',
    },
  })
  createService = async (clusterId, namespace, body) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/services`, clusterId)
    const data = await this.client.basicPost<GetClusterKubeServicesItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createService',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/services/:service',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'service'],
    error: { isClusterError: true, k8sResource: 'Service' },
  })
  deleteService = async (clusterId, namespace, name) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/services/${name}`, clusterId)
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteService',
      },
    })
  };

  [DataKeys.KubeServices] = {
    create: this.createService.bind(this),
    list: this.getClusterKubeServices.bind(this),
    delete: this.deleteService.bind(this),
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/serviceaccounts',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'ServiceAccount' },
  })
  createServiceAccount = async (clusterId, namespace, body) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/serviceaccounts`, clusterId)
    return this.client.basicPost({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createServiceAccount',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/serviceaccounts',
    type: 'GET',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'ServiceAccount' },
  })
  getServiceAccounts = async (clusterId, namespace) => {
    const url = getApiUrl(`api/v1/namespaces/${namespace}/serviceaccounts`, clusterId)
    const response = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getServiceAccounts',
      },
    })
    return response && response.items
  }

  /* RBAC */
  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/rbac.authorization.k8s.io/v1/roles',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Role' },
  })
  getClusterRoles = async (clusterId) => {
    const url = getApiUrl(`apis/rbac.authorization.k8s.io/v1/roles`, clusterId)
    const data = await this.client.basicGet<GetClusterRoles>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterRoles',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/roles',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'Role' },
  })
  createClusterRole = async (clusterId, namespace, body) => {
    const url = getApiUrl(
      `apis/rbac.authorization.k8s.io/v1/namespaces/${namespace}/roles`,
      clusterId,
    )
    const response = await this.client.basicPost({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createClusterRole',
      },
    })
    return normalizeClusterizedUpdate(clusterId, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/roles/:role',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'role'],
    error: { isClusterError: true, k8sResource: 'Role' },
  })
  updateClusterRole = async (clusterId, namespace, name, body) => {
    const url = getApiUrl(
      `apis/rbac.authorization.k8s.io/v1/namespaces/${namespace}/roles/${name}`,
      clusterId,
    )
    const response = await this.client.basicPut({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateClusterRole',
      },
    })
    return normalizeClusterizedUpdate(clusterId, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/roles/:role',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'role'],
    error: { isClusterError: true, k8sResource: 'Role' },
  })
  deleteClusterRole = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/rbac.authorization.k8s.io/v1/namespaces/${namespace}/roles/${name}`,
      clusterId,
    )
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteClusterRole',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/rbac.authorization.k8s.io/v1/clusterroles',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'ClusterRole' },
  })
  getClusterClusterRoles = async (clusterId) => {
    const url = getApiUrl(`apis/rbac.authorization.k8s.io/v1/clusterroles`, clusterId)
    const response = await this.client.basicGet<GetClusterClusterRoles>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterClusterRoles',
      },
    })
    return normalizeClusterizedResponse(clusterId, response)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/rbac.authorization.k8s.io/v1/clusterroles',
    type: 'POST',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'ClusterRole' },
  })
  createClusterClusterRole = async (clusterId, body) => {
    const url = getApiUrl(`apis/rbac.authorization.k8s.io/v1/clusterroles`, clusterId)
    const response = await this.client.basicPost({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createClusterClusterRole',
      },
    })
    return normalizeClusterizedUpdate(clusterId, response)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/rbac.authorization.k8s.io/v1/clusterroles/:clusterRole',
    type: 'PUT',
    params: ['clusterId', 'clusterRole'],
    error: { isClusterError: true, k8sResource: 'ClusterRole' },
  })
  updateClusterClusterRole = async (clusterId, name, body) => {
    const url = getApiUrl(`apis/rbac.authorization.k8s.io/v1/clusterroles/${name}`, clusterId)
    const response = await this.client.basicPut({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateClusterClusterRole',
      },
    })
    return normalizeClusterizedUpdate(clusterId, response)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/rbac.authorization.k8s.io/v1/clusterroles/:clusterRole',
    type: 'DELETE',
    params: ['clusterId', 'clusterRole'],
    error: { isClusterError: true, k8sResource: 'RoleBinding' },
  })
  deleteClusterClusterRole = async (clusterId, name) => {
    const url = getApiUrl(`apis/rbac.authorization.k8s.io/v1/clusterroles/${name}`, clusterId)
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteClusterClusterRole',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/rbac.authorization.k8s.io/v1/rolebindings',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'RoleBinding' },
  })
  getClusterRoleBindings = async (clusterId) => {
    const url = getApiUrl(`apis/rbac.authorization.k8s.io/v1/rolebindings`, clusterId)
    const data = await this.client.basicGet<GetCluster>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterRoleBindings',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/rolebindings',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'RoleBinding' },
  })
  createClusterRoleBinding = async (clusterId, namespace, body) => {
    const url = getApiUrl(
      `apis/rbac.authorization.k8s.io/v1/namespaces/${namespace}/rolebindings`,
      clusterId,
    )
    const response = await this.client.basicPost({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createClusterRoleBinding',
      },
    })
    return normalizeClusterizedUpdate(clusterId, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/rolebindings/:roleBinding',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'roleBinding'],
    error: { isClusterError: true, k8sResource: 'RoleBinding' },
  })
  updateClusterRoleBinding = async (clusterId, namespace, name, body) => {
    const url = getApiUrl(
      `apis/rbac.authorization.k8s.io/v1/namespaces/${namespace}/rolebindings/${name}`,
      clusterId,
    )
    const response = await this.client.basicPut({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateClusterRoleBinding',
      },
    })
    return normalizeClusterizedUpdate(clusterId, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/rbac.authorization.k8s.io/v1/namespaces/:namespace/rolebindings/:roleBinding',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'roleBinding'],
    error: { isClusterError: true, k8sResource: 'RoleBinding' },
  })
  deleteClusterRoleBinding = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/rbac.authorization.k8s.io/v1/namespaces/${namespace}/rolebindings/${name}`,
      clusterId,
    )
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteClusterRoleBinding',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/rbac.authorization.k8s.io/v1/clusterrolebindings',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'ClusterRoleBinding' },
  })
  getClusterClusterRoleBindings = async (clusterId) => {
    const url = getApiUrl(`apis/rbac.authorization.k8s.io/v1/clusterrolebindings`, clusterId)
    const response = await this.client.basicGet<GetCluster>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterClusterRoleBindings',
      },
    })
    return normalizeClusterizedResponse(clusterId, response)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/rbac.authorization.k8s.io/v1/clusterrolebindings',
    type: 'POST',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'ClusterRoleBinding' },
  })
  createClusterClusterRoleBinding = async (clusterId, body) => {
    const url = getApiUrl(`apis/rbac.authorization.k8s.io/v1/clusterrolebindings`, clusterId)
    const response = await this.client.basicPost({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createClusterClusterRoleBinding',
      },
    })
    return normalizeClusterizedUpdate(clusterId, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/rbac.authorization.k8s.io/v1/clusterrolebindings/:clusterRoleBinding',
    type: 'PUT',
    params: ['clusterId', 'clusterRoleBinding'],
    error: { isClusterError: true, k8sResource: 'ClusterRoleBinding' },
  })
  updateClusterClusterRoleBinding = async (clusterId, name, body) => {
    const url = getApiUrl(
      `apis/rbac.authorization.k8s.io/v1/clusterrolebindings/${name}`,
      clusterId,
    )
    const response = await this.client.basicPut({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateClusterClusterRoleBinding',
      },
    })
    return normalizeClusterizedUpdate(clusterId, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/rbac.authorization.k8s.io/v1/clusterrolebindings/:clusterRoleBinding',
    type: 'DELETE',
    params: ['clusterId', 'clusterRoleBinding'],
    error: { isClusterError: true, k8sResource: 'ClusterRoleBinding' },
  })
  deleteClusterClusterRoleBinding = async (clusterId, name) => {
    const url = getApiUrl(
      `apis/rbac.authorization.k8s.io/v1/clusterrolebindings/${name}`,
      clusterId,
    )
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteClusterClusterRoleBinding',
      },
    })
  }

  /* RBAC Profiles */
  @trackApiMethodMetadata({
    url: '/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusterprofiles',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'ClusterProfile' },
  })
  getRbacProfiles = async () => {
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusterprofiles`
    const response = await this.client.basicGet<ClusterProfilesResponse>({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getRbacProfiles',
      },
    })
    return response && response.items
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusterprofiles',
    type: 'POST',
    error: { isClusterError: true, k8sResource: 'ClusterProfile' },
  })
  createRbacProfile = async (body) => {
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusterprofiles`
    const response = await this.client.basicPost({
      url,
      body,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'createRbacProfile',
      },
    })
    return response
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusterprofiles/:profileName',
    type: 'PATCH',
    params: ['profileName'],
    error: { isClusterError: true, k8sResource: 'ClusterProfile' },
  })
  patchRbacProfile = async (name, body) => {
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusterprofiles/${name}`
    const response = await this.client.basicPatch<any>({
      url,
      body,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'patchRbacProfile',
        config: {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        },
      },
    })
    return response
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusterprofiles/:profileName',
    type: 'DELETE',
    params: ['profileName'],
    error: { isClusterError: true, k8sResource: 'ClusterProfile' },
  })
  deleteRbacProfile = async (name) => {
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusterprofiles/${name}`
    await this.client.basicDelete({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteRbacProfile',
      },
    })
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/:namespace/clusterprofiles/:profileName/details',
    type: 'GET',
    params: ['namespace', 'profileName'],
    error: { isClusterError: true, k8sResource: 'ClusterProfileDetail' },
  })
  getRbacProfileDetails = async (namespace, name): Promise<IRbacProfileDetails> => {
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/${namespace}/clusterprofiles/${name}/details`
    const response = await this.client.basicGet<{
      data: {
        apiVersion: string
        data: string
      }
    }>({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getRbacProfileDetails',
        extractNestedData: false,
      },
    })

    return {
      apiVersion: response?.apiVersion,
      data: tryJsonParse(response?.data),
    }
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/:namespace/clusterprofiles/:profileName/details',
    type: 'PUT',
    params: ['namespace', 'profileName'],
    error: { isClusterError: true, k8sResource: 'ClusterProfileDetail' },
  })
  putRbacProfileDetails = async (
    name: string,
    namespace: string,
    profileDetails: IRbacProfileDetails,
  ) => {
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/${namespace}/clusterprofiles/${name}/details`

    return this.client.basicPut<any>({
      url,
      body: {
        ...profileDetails,
        name,
        metadata: { name },
        kind: 'ClusterProfileDetail',
        data: JSON.stringify(profileDetails.data),
      },
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'putRbacProfileDetails',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusterprofilebindings',
    type: 'GET',
    error: { isClusterError: true, k8sResource: 'ClusterProfileBinding' },
  })
  getRbacProfileBindings = async () => {
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusterprofilebindings`
    const response = await this.client.basicGet<ClusterProfileBindingsResponse>({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getRbacProfileBindings',
      },
    })
    return response && response.items
  }

  @trackApiMethodMetadata({
    url: '/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusterprofilebindings',
    type: 'POST',
    error: { isClusterError: true, k8sResource: 'ClusterProfileBinding' },
  })
  createRbacProfileBinding = async (body) => {
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusterprofilebindings`
    const response = await this.client.basicPost({
      url,
      body,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'createClusterClusterRoleBinding',
      },
    })
    return response
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusterprofilebindings/:bindingName',
    type: 'GET',
    params: ['bindingName'],
    error: { isClusterError: true, k8sResource: 'ClusterProfileBinding' },
  })
  getRbacProfileBinding = async (bindingName) => {
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusterprofilebindings/${bindingName}`
    const response = await this.client.basicGet<any>({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getRbacProfileBinding',
      },
    })
    return response
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusterprofilebindings/:bindingName/details',
    type: 'GET',
    params: ['bindingName'],
    error: { isClusterError: true, k8sResource: 'ClusterProfileBinding' },
  })
  getRbacProfileBindingDetails = async (bindingName) => {
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusterprofilebindings/${bindingName}/details`
    const response = await this.client.rawGet<ClusterProfileBindingDetailsResponse>({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getRbacProfileBindingDetails',
      },
      config: this.client.getAuthHeaders(),
    })
    // Response body has a data.data property, set normalize false
    return response.data
  }

  @trackApiMethodMetadata({
    url:
      '/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusterprofilebindings/:bindingName',
    type: 'DELETE',
    params: ['bindingName'],
    error: { isClusterError: true, k8sResource: 'ClusterProfileBinding' },
  })
  deleteRbacProfileBinding = async (name) => {
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusterprofilebindings/${name}`
    await this.client.basicDelete({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteRbacProfileBinding',
      },
    })
  }

  /* Managed Apps */
  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/prometheuses',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Prometheus' },
  })
  getPrometheusInstances = async (clusterId) => {
    const url = getApiUrl(`apis/monitoring.coreos.com/v1/prometheuses`, clusterId)
    const response = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPrometheusInstances',
      },
    })
    return normalizeClusterizedResponse(clusterId, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/namespaces/:namespace/prometheuses/:prometheusInstance',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'prometheusInstance'],
    error: { isClusterError: true, k8sResource: 'Prometheus' },
  })
  updatePrometheusInstance = async (data) => {
    const { clusterUuid, namespace, name } = data
    const body = [
      { op: 'replace', path: '/spec/replicas', value: data.replicas },
      { op: 'replace', path: '/spec/retention', value: data.retention },
      { op: 'replace', path: '/spec/resources/requests/cpu', value: data.cpu },
      { op: 'replace', path: '/spec/resources/requests/memory', value: data.memory },
    ]
    const url = `/clusters/${clusterUuid}/k8sapi/apis/monitoring.coreos.com/v1/namespaces/${namespace}/prometheuses/${name}`
    const response = await this.client.basicPatch({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updatePrometheusInstance',
      },
    })
    return normalizeClusterizedUpdate(clusterUuid, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/namespaces/:namespace/prometheuses/:prometheusInstance',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'prometheusInstance'],
    error: { isClusterError: true, k8sResource: 'Prometheus' },
  })
  deletePrometheusInstance = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/monitoring.coreos.com/v1/namespaces/${namespace}/prometheuses/${name}`,
      clusterId,
    )
    await this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deletePrometheusInstance',
      },
    })
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/namespaces/:namespace/prometheuses/:prometheusInstance',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'prometheusInstance'],
    error: { isClusterError: true, k8sResource: 'Prometheus' },
  })
  createPrometheusInstance = async (clusterId, data) => {
    const requests: { cpu?: string; memory?: string } = {}
    if (data.cpu) {
      requests.cpu = data.cpu
    }
    if (data.memory) {
      requests.memory = data.memory
    }
    // if (data.storage) { requests.storage = data.storage }

    const apiVersion = 'monitoring.coreos.com/v1'

    const serviceMonitor = {
      prometheus: data.name,
      role: 'service-monitor',
    }

    const appLabels = keyValueArrToObj(data.appLabels)
    const ruleSelector = {
      prometheus: data.name,
      role: 'alert-rules',
    }

    const prometheusBody = {
      apiVersion,
      kind: 'Prometheus',
      metadata: {
        name: data.name,
        namespace: data.namespace,
      },
      spec: {
        replicas: data.replicas,
        retention: data.retention,
        resources: { requests },
        serviceMonitorSelector: { matchLabels: serviceMonitor },
        serviceAccountName: data.serviceAccountName,
        ruleSelector: { matchLabels: ruleSelector },
      },
    }

    // TODO: How do we specifiy "Enable persistent storage" in the API call?  What does this field mean in the
    // context of a Prometheus Instance?  Where will it be stored?  Do we need to specify PVC and StorageClasses?

    const serviceMonitorBody = {
      apiVersion,
      kind: 'ServiceMonitor',
      metadata: {
        name: `${data.name}-service-monitor`,
        namespace: data.namespace,
        labels: serviceMonitor,
      },
      spec: {
        endpoints: [{ port: data.port }],
        selector: { matchLabels: appLabels },
      },
    }

    /*
    let alertManagerBody = {
      // TODO: what goes in here
    }
    */

    const prometheusRulesBody = {
      apiVersion,
      kind: 'PrometheusRule',
      metadata: {
        labels: ruleSelector,
        name: `${data.name}-prometheus-rules`,
        namespace: data.namespace,
      },
      spec: {
        groups: [
          {
            name: `${data.name}-rule-group`,
            rules: data.rules,
          },
        ],
      },
    }

    const prometheusUrl = getApiUrl(
      `apis/monitoring.coreos.com/v1/namespaces/${data.namespace}/prometheuses`,
      clusterId,
    )
    const response = await this.client.basicPost({
      url: prometheusUrl,
      body: prometheusBody,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createPrometheusInstance',
      },
    })
    const serviceMonitorUrl = getApiUrl(
      `apis/monitoring.coreos.com/v1/namespaces/${data.namespace}/servicemonitors`,
      clusterId,
    )
    await this.client.basicPost({
      url: serviceMonitorUrl,
      body: serviceMonitorBody,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createPrometheusInstance',
      },
    })
    const url = getApiUrl(
      `apis/monitoring.coreos.com/v1/namespaces/${data.namespace}/prometheusrules`,
      clusterId,
    )
    await this.client.basicPost({
      url,
      body: prometheusRulesBody,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createPrometheusInstance',
      },
    })
    // this.client.basicPost(getApiUrl(/monitoring.coreos.com/v1/alertmanagers`, alertManagerBody), clusterId)
    return response
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/api/v1/namespaces/pf9-monitoring/services/http:sys-alertmanager:9093/proxy/api/v2/alerts',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'AlertManagerAlert' },
  })
  getAlertManagerAlerts = async (clusterId): Promise<AlertManagerAlert[]> => {
    const url = getApiUrl(
      `api/v1/namespaces/pf9-monitoring/services/http:sys-alertmanager:9093/proxy/api/v2/alerts`,
      clusterId,
    )
    const alerts = await this.client.basicGet<AlertManagerRaw[]>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPrometheusAlerts',
      },
    })
    return alerts?.map((alert) => ({
      ...alert,
      clusterId,
      id: `${alert.fingerprint}-${clusterId}`,
    }))
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/api/v1/namespaces/pf9-monitoring/services/http:sys-alertmanager:9093/proxy/api/v2/silences',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Silence' },
  })
  getAlertManagerSilences = async (clusterId): Promise<any> => {
    const url = getApiUrl(
      `api/v1/namespaces/pf9-monitoring/services/http:sys-alertmanager:9093/proxy/api/v2/silences`,
      clusterId,
    )
    const silences = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getSilences',
      },
    })
    return silences?.map((silence) => ({
      ...silence,
      clusterId: clusterId,
    }))
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/api/v1/namespaces/pf9-monitoring/services/http:sys-alertmanager:9093/proxy/api/v2/silences',
    type: 'POST',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Silence' },
  })
  createAlertManagerSilence = async (clusterId, body): Promise<any> => {
    const url = getApiUrl(
      `api/v1/namespaces/pf9-monitoring/services/http:sys-alertmanager:9093/proxy/api/v2/silences`,
      clusterId,
    )
    const silence = await this.client.basicPost<any>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createSilence',
      },
    })
    return {
      ...silence,
      clusterId: clusterId,
    }
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/api/v1/namespaces/pf9-monitoring/services/http:sys-alertmanager:9093/proxy/api/v2/silence/:silenceId',
    type: 'DELETE',
    params: ['clusterId', 'silenceId'],
    error: { isClusterError: true, k8sResource: 'Silence' },
  })
  deleteAlertManagerSilence = async (clusterId, silenceId): Promise<any> => {
    const url = getApiUrl(
      `api/v1/namespaces/pf9-monitoring/services/http:sys-alertmanager:9093/proxy/api/v2/silence/${silenceId}`,
      clusterId,
    )
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteSilence',
      },
    })
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/api/v1/namespaces/pf9-monitoring/services/http:sys-prometheus:9090/proxy/api/v1/alerts',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'PrometheusAlert' },
  })
  getPrometheusAlerts = async (clusterId) => {
    const url = getApiUrl(
      `api/v1/namespaces/pf9-monitoring/services/http:sys-prometheus:9090/proxy/api/v1/alerts`,
      clusterId,
    )
    const response = await this.client.basicGet<GetPrometheusAlerts>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPrometheusAlerts',
      },
    })
    return response.alerts.map((alert) => ({
      ...alert,
      clusterId: clusterId,
      id: uuid.v4(),
    }))
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/api/v1/namespaces/pf9-monitoring/services/http:sys-prometheus:9090/proxy/api/v1/rules',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'PrometheusRule' },
  })
  getPrometheusAlertRules = async (clusterId) => {
    const url = getApiUrl(
      `api/v1/namespaces/pf9-monitoring/services/http:sys-prometheus:9090/proxy/api/v1/rules`,
      clusterId,
    )
    const response = await this.client.basicGet<GetPrometheusAlertRules>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPrometheusAlertRules',
      },
    })
    const alertRules = response.groups
      .flatMap((group) => {
        return group.rules
      })
      .filter((rule) => rule.type === 'alerting')
      .map((rule) => ({
        ...rule,
        clusterId,
        id: `${rule.name}${clusterId}`,
      }))
    return alertRules
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/api/v1/namespaces/pf9-monitoring/services/http:sys-prometheus:9090/proxy/api/v1/query_range',
    type: 'GET',
    params: ['clusterId', 'query', 'start', 'end', 'step'],
    error: { isClusterError: true, k8sResource: 'PrometheusAlert' },
  })
  getPrometheusAlertsOverTime = async (
    clusterId,
    startTime,
    endTime,
    step,
  ): Promise<IAlertOverTime[]> => {
    const url = getApiUrl(
      `api/v1/namespaces/pf9-monitoring/services/http:sys-prometheus:9090/proxy/api/v1/query_range?query=ALERTS&start=${startTime}&end=${endTime}&step=${step}`,
      clusterId,
    )
    const response = await this.client.basicGet<GetPrometheusAlertsOverTime>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPrometheusAlertsOverTime',
      },
    })
    return response.result.map((alert) => ({
      ...alert,
      startTime,
      endTime,
      clusterId,
    }))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/servicemonitors',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'ServiceMonitor' },
  })
  getPrometheusServiceMonitors = async (clusterId) => {
    const url = getApiUrl(`apis/monitoring.coreos.com/v1/servicemonitors`, clusterId)
    const response = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPrometheusServiceMonitors',
      },
    })
    return normalizeClusterizedResponse(clusterId, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/namespaces/:namespace/servicemonitors/:serviceMonitor',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'serviceMonitor'],
    error: { isClusterError: true, k8sResource: 'ServiceMonitor' },
  })
  updatePrometheusServiceMonitor = async (data) => {
    const { clusterUuid, namespace, name } = data
    const body = [
      {
        op: 'replace',
        path: '/metadata/labels',
        value: data.labels,
      },
    ]
    const url = `/clusters/${clusterUuid}/k8sapi/apis/monitoring.coreos.com/v1/namespaces/${namespace}/servicemonitors/${name}`
    const response = await this.client.basicPatch({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updatePrometheusServiceMonitor',
      },
    })
    return normalizeClusterizedUpdate(clusterUuid, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/namespaces/:namespace/servicemonitors/:serviceMonitor',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'serviceMonitor'],
    error: { isClusterError: true, k8sResource: 'ServiceMonitor' },
  })
  deletePrometheusServiceMonitor = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/monitoring.coreos.com/v1/namespaces/${namespace}/servicemonitors/${name}`,
      clusterId,
    )
    await this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deletePrometheusServiceMonitor',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/prometheusrules',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'PrometheusRule' },
  })
  getPrometheusRules = async (clusterId) => {
    const url = getApiUrl(`apis/monitoring.coreos.com/v1/prometheusrules`, clusterId)
    const response = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPrometheusRules',
      },
    })
    return normalizeClusterizedResponse(clusterId, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/namespaces/:namespace/prometheusrules/:prometheusRule',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'prometheusRule'],
    error: { isClusterError: true, k8sResource: 'PrometheusRule' },
  })
  updatePrometheusRules = async (rulesObject) => {
    const { clusterUuid, namespace, name } = rulesObject
    const body = [
      {
        op: 'replace',
        path: '/spec/groups/0/rules',
        value: rulesObject.rules,
      },
    ]
    const url = `/clusters/${clusterUuid}/k8sapi/apis/monitoring.coreos.com/v1/namespaces/${namespace}/prometheusrules/${name}`
    const response = await this.client.basicPatch({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updatePrometheusRules',
      },
    })
    return normalizeClusterizedUpdate(clusterUuid, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/namespaces/:namespace/prometheusrules/:prometheusRule',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'prometheusRule'],
    error: { isClusterError: true, k8sResource: 'PrometheusRule' },
  })
  deletePrometheusRule = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/monitoring.coreos.com/v1/namespaces/${namespace}/prometheusrules/${name}`,
      clusterId,
    )
    await this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deletePrometheusRule',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/alertmanagers',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Alertmanager' },
  })
  getPrometheusAlertManagers = async (clusterId) => {
    const url = getApiUrl(`apis/monitoring.coreos.com/v1/alertmanagers`, clusterId)
    const response = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPrometheusAlertManagers',
      },
    })
    return normalizeClusterizedResponse(clusterId, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/namespaces/:namespace/alertmanagers/:alertManager',
    type: 'PATCH',
    params: ['clusterId', 'namespace', 'alertManager'],
    error: { isClusterError: true, k8sResource: 'Alertmanager' },
  })
  updatePrometheusAlertManager = async (data) => {
    const { clusterUuid, namespace, name } = data
    const body = [{ op: 'replace', path: '/spec/replicas', value: data.replicas }]
    const url = `/clusters/${clusterUuid}/k8sapi/apis/monitoring.coreos.com/v1/namespaces/${namespace}/alertmanagers/${name}`
    const response = await this.client.basicPatch({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updatePrometheusAlertManager',
      },
    })
    return normalizeClusterizedUpdate(clusterUuid, response)
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/apis/monitoring.coreos.com/v1/namespaces/:namespace/alertmanagers/:alertManager',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'alertManager'],
    error: { isClusterError: true, k8sResource: 'Alertmanager' },
  })
  deletePrometheusAlertManager = async (clusterId, namespace, name) => {
    const url = getApiUrl(
      `apis/monitoring.coreos.com/v1/namespaces/${namespace}/alertmanagers/${name}`,
      clusterId,
    )
    await this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deletePrometheusAlertManager',
      },
    })
  }

  getPrometheusDashboardLink = async (instance) =>
    `${await this.getApiEndpoint()}/clusters/${instance.clusterUuid}/k8sapi${instance.dashboard}`

  // API Resources
  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'APIGroupList' },
  })
  getApiGroupList = async (clusterId) => {
    const url = getApiUrl(`apis`, clusterId)
    return this.client.basicGet<APIGroupsResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getApiGroupList',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/:apiGroup',
    type: 'GET',
    params: ['clusterId', 'apiGroup'],
    error: { isClusterError: true, k8sResource: 'APIGroup' },
  })
  getApiResourcesList = async (config) => {
    const { clusterId, apiGroup } = config
    const url = getApiUrl(`apis/${apiGroup}`, clusterId)
    return this.client.basicGet<APIResourcesResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getApiResourcesList',
      },
    })
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'APIResourceList' },
  })
  getCoreApiResourcesList = async (clusterId) => {
    const url = getApiUrl(`api/v1`, clusterId)
    return this.client.basicGet<IRbacAPIGroup>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCoreApiResourcesList',
      },
    })
  }

  // Get addons for ALL clusters
  getAllClusterAddons = async (labelSelector = undefined): Promise<ClusterAddon[]> => {
    const url = '/sunpike/apis/sunpike.platform9.com/v1alpha2/clusteraddons'
    const result = await this.client.basicGet<ClusterAddons>({
      url: labelSelector ? createUrlWithQueryString(url, { labelSelector }) : url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterAddons',
      },
    })
    return result?.items
  }

  getClusterAddons = async (clusterId, namespace = 'default') => {
    const clusterLabel = `sunpike.pf9.io/cluster=${clusterId}`
    const url = createUrlWithQueryString(
      `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/${namespace}/clusteraddons`,
      { labelSelector: clusterLabel },
    )
    const result = await this.client.basicGet<ClusterAddons>({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterAddons',
      },
    })
    return result?.items
  }

  // TODO: Should these be moved to sunpike? Or do they have a different
  // base URL?
  deleteClusterAddon = async (addonName) => {
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/default/clusteraddons/${addonName}`
    return this.client.basicDelete({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteClusterAddon',
      },
    })
  }

  addClusterAddon = async (body) => {
    const namespace = body?.metadata?.namespace || 'default'
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/${namespace}/clusteraddons`
    return this.client.basicPost({
      url,
      version: 'v4',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'addClusterAddon',
      },
    })
  }

  editClusterAddon = async (addonName, body) => {
    const namespace = body?.metadata?.namespace || 'default'
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/${namespace}/clusteraddons/${addonName}`
    return this.client.basicPatch({
      url,
      version: 'v4',
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'editClusterAddon',
        config: {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        },
      },
    })
  }

  getClusterAgents = async () => {
    const url = `/sunpike/apis/sunpike.platform9.com/v1alpha2/namespaces/${this.client.activeProjectId}/clusteragents`
    const result = await this.client.basicGet<ClusterAgentsResponse>({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterAgents',
      },
    })
    return result?.items
  }

  @trackApiMethodMetadata({
    url: '/externalClusters/:clusterId/deploymentYAML',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: false },
  })
  getEcoInstallationYaml = async (clusterId) => {
    const url = `/externalClusters/${clusterId}/deploymentYAML`
    const result = await this.client.basicGet<any>({
      url,
      version: 'v4',
      options: {
        clsName: this.getClassName(),
        mthdName: 'getEcoInstallationYaml',
      },
    })
    return result
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/metrics.k8s.io/v1beta1/namespaces/:namespace/pods',
    type: 'GET',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'PodMetricsList' },
  })
  getPodsMetrics = async (clusterId, namespace) => {
    const url = getApiUrl(`apis/metrics.k8s.io/v1beta1/namespaces/${namespace}/pods`, clusterId)
    const data = await this.client.basicGet<PodMetricsResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPodsMetrics',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/configmaps',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'ConfigMap' },
  })
  getConfigMaps = async (clusterId) => {
    const url = getApiUrl(`api/v1/configmaps`, clusterId)
    const data = await this.client.basicGet<ConfigMapsResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getConfigMaps',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/api/v1/persistentvolumeclaims',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'PersistentVolumeClaim' },
  })
  getPersistentVolumeClaims = async (clusterId) => {
    const url = getApiUrl(`api/v1/persistentvolumeclaims`, clusterId)
    const data = await this.client.basicGet<PersistentVolumeClaimsResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPersistentVolumeClaims',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/events.k8s.io/v1/events',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: true, k8sResource: 'Events' },
  })
  getClusterEvents = async (clusterId) => {
    const url = getApiUrl(`apis/events.k8s.io/v1/events`, clusterId)
    const data = await this.client.basicGet<EventsResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getClusterEvents',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/events.k8s.io/v1/namespaces/:namespace/events/:name',
    type: 'DELETE',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: true, k8sResource: 'Events' },
  })
  deleteClusterEvent = async (clusterId, namespace, eventName) => {
    const url = getApiUrl(
      `apis/events.k8s.io/v1/namespaces/${namespace}/events/${eventName}`,
      clusterId,
    )
    return this.client.basicDelete({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteClusterEvent',
      },
    })
  }

  @trackApiMethodMetadata({
    url:
      '/clusters/:clusterId/k8sapi/api/v1/namespaces/:namespace/pods/:name/log?=container:container',
    type: 'GET',
    params: ['clusterId', 'namespace', 'pod', 'container'],
    error: { isClusterError: true, k8sResource: 'Pod Logs' },
  })
  getPodLogs = async (clusterId, namespace, pod, params) => {
    const url = getApiUrl(`/api/v1/namespaces/${namespace}/pods/${pod}/log`, clusterId, {}, params)

    const data = await this.client.rawGet<string>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getPodLogs',
      },
      config: this.client.getAuthHeaders(),
    })
    return data
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/statefulsets',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: false, k8sResource: 'StatefulSets' },
  })
  getStatefulSets = async (clusterId: string, namespace?: string) => {
    const url = namespace
      ? getApiUrl(`apis/apps/v1/namespaces/${namespace}/statefulsets`, clusterId)
      : getApiUrl(`apis/apps/v1/statefulsets`, clusterId)
    const data = await this.client.basicGet<StatefulSetResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getStatefulSets',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/statefulsets/:name',
    type: 'GET',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: false, k8sResource: 'StatefulSets' },
  })
  getStatefulSet = async (clusterId, namespace, name) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/statefulsets/${name}`, clusterId)
    const data = await this.client.basicGet<StatefulSet>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getStatefulSet',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/statefulsets',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: false, k8sResource: 'StatefulSets' },
  })
  createStatefulSet = async (clusterId, namespace, body) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/statefulsets`, clusterId)
    const data = await this.client.basicPost<StatefulSet>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createStatefulSet',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/statefulsets/:name',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: false, k8sResource: 'StatefulSet' },
  })
  updateStatefulSet = async (clusterId, namespace, name, body) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/statefulsets/${name}`, clusterId)
    const data = await this.client.basicPut<StatefulSet>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateStatefulSet',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/statefulsets/:name',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: false, k8sResource: 'StatefulSet' },
  })
  deleteStatefulSet = async (clusterId, namespace, name) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/statefulsets/${name}`, clusterId)
    const data = await this.client.basicDelete<StatefulSet>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteStatefulSet',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/replicasets',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: false, k8sResource: 'ReplicaSets' },
  })
  getReplicaSets = async (clusterId: string, namespace?: string) => {
    const url = namespace
      ? getApiUrl(`apis/apps/v1/namespaces/${namespace}/replicasets`, clusterId)
      : getApiUrl(`apis/apps/v1/replicasets`, clusterId)
    const data = await this.client.basicGet<ReplicaSetsResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getReplicaSets',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/replicasets/:name',
    type: 'GET',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: false, k8sResource: 'ReplicaSet' },
  })
  getReplicaSet = async (clusterId, namespace, name) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/replicasets/${name}`, clusterId)
    const data = await this.client.basicGet<ReplicaSetsItem>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getReplicaSet',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/replicasets',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: false, k8sResource: 'ReplicaSet' },
  })
  createReplicaSet = async (clusterId, namespace, body) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/replicasets`, clusterId)
    const data = await this.client.basicPost<ReplicaSetsItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createReplicaSet',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/replicasets/:name',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: false, k8sResource: 'ReplicaSet' },
  })
  updateReplicaSet = async (clusterId, namespace, name, body) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/replicasets/${name}`, clusterId)
    const data = await this.client.basicPut<ReplicaSetsItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateReplicaSet',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/replicasets/:name',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: false, k8sResource: 'ReplicaSet' },
  })
  deleteReplicaSet = async (clusterId, namespace, name) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/replicasets/${name}`, clusterId)
    const data = await this.client.basicDelete<ReplicaSetsItem>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteReplicaSet',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/batch/v1/cronjobs',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: false, k8sResource: 'Cronjobs' },
  })
  getCronjobs = async (clusterId, apiVersion = 'v1', namespace?: string) => {
    const url = namespace
      ? getApiUrl(`apis/batch/${apiVersion}/namespaces/${namespace}/cronjobs`, clusterId)
      : getApiUrl(`apis/batch/${apiVersion}/cronjobs`, clusterId)
    const data = await this.client.basicGet<CronjobResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCronjobs',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/cronjobs/:name',
    type: 'GET',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: false, k8sResource: 'Cronjobs' },
  })
  getCronjob = async (clusterId, namespace, name, apiVersion = 'v1') => {
    const url = getApiUrl(
      `apis/batch/${apiVersion}/namespaces/${namespace}/cronjobs/${name}`,
      clusterId,
    )
    const data = await this.client.basicGet<CronjobItem>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getCronjob',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/cronjobs',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: false, k8sResource: 'Cronjob' },
  })
  createCronjob = async (clusterId, namespace, body, apiVersion = 'v1') => {
    const url = getApiUrl(`apis/batch/${apiVersion}/namespaces/${namespace}/cronjobs`, clusterId)
    const data = await this.client.basicPost<CronjobItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createCronjob',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/cronjobs/:name',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: false, k8sResource: 'Cronjob' },
  })
  updateCronjob = async (clusterId, namespace, name, body, apiVersion = 'v1') => {
    const url = getApiUrl(
      `apis/batch/${apiVersion}/namespaces/${namespace}/cronjobs/${name}`,
      clusterId,
    )
    const data = await this.client.basicPut<CronjobItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateCronjob',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/cronjobs/:name',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: false, k8sResource: 'Cronjob' },
  })
  deleteCronjob = async (clusterId, namespace, name, apiVersion = 'v1') => {
    const url = getApiUrl(
      `apis/batch/${apiVersion}/namespaces/${namespace}/cronjobs/${name}`,
      clusterId,
    )
    const data = await this.client.basicDelete<CronjobItem>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteCronjob',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/batch/v1/jobs',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: false, k8sResource: 'Jobs' },
  })
  getJobs = async (clusterId, apiVersion = 'v1', namespace?: string) => {
    const url = namespace
      ? getApiUrl(`apis/batch/${apiVersion}/namespaces/${namespace}/jobs`, clusterId)
      : getApiUrl(`apis/batch/${apiVersion}/jobs`, clusterId)
    const data = await this.client.basicGet<JobsResponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getJobs',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/jobs/:name',
    type: 'GET',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: false, k8sResource: 'Jobs' },
  })
  getJob = async (clusterId, namespace, name, apiVersion = 'v1') => {
    const url = getApiUrl(
      `apis/batch/${apiVersion}/namespaces/${namespace}/jobs/${name}`,
      clusterId,
    )
    const data = await this.client.basicGet<JobItem>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getJob',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/jobs',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: false, k8sResource: 'Job' },
  })
  createJob = async (clusterId, namespace, body, apiVersion = 'v1') => {
    const url = getApiUrl(`apis/batch/${apiVersion}/namespaces/${namespace}/jobs`, clusterId)
    const data = await this.client.basicPost<JobItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createJob',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/jobs/:name',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: false, k8sResource: 'Job' },
  })
  updateJob = async (clusterId, namespace, name, body, apiVersion = 'v1') => {
    const url = getApiUrl(
      `apis/batch/${apiVersion}/namespaces/${namespace}/jobs/${name}`,
      clusterId,
    )
    const data = await this.client.basicPut<JobItem>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateJob',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/jobs/:name',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: true, k8sResource: 'Job' },
  })
  deleteJob = async (clusterId, namespace, name, apiVersion = 'v1') => {
    const url = getApiUrl(
      `apis/batch/${apiVersion}/namespaces/${namespace}/jobs/${name}`,
      clusterId,
    )
    const data = await this.client.basicDelete<JobItem>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteJob',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/daemonsets',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: false, k8sResource: 'DaemonSets' },
  })
  getDaemonSets = async (clusterId: string, namespace?: string) => {
    const url = namespace
      ? getApiUrl(`apis/apps/v1/namespaces/${namespace}/daemonsets`, clusterId)
      : getApiUrl(`apis/apps/v1/daemonsets`, clusterId)
    const data = await this.client.basicGet<GetDaemonSetsReponse>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getDaemonSets',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/daemonsets/:name',
    type: 'GET',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: false, k8sResource: 'DaemonSet' },
  })
  getDaemonSet = async (clusterId, namespace, name) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/daemonsets/${name}`, clusterId)
    const data = await this.client.basicGet<DaemonSet>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getDaemonSet',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/daemonsets',
    type: 'POST',
    params: ['clusterId', 'namespace'],
    error: { isClusterError: false, k8sResource: 'DaemonSet' },
  })
  createDaemonSet = async (clusterId, namespace, body) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/daemonsets`, clusterId)
    const data = await this.client.basicPost<DaemonSet>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'createDaemonSet',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/batch/v1/namespaces/:namespace/jobs/:name',
    type: 'PUT',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: false, k8sResource: 'DaemonSet' },
  })
  updateDaemonSet = async (clusterId, namespace, name, body) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/daemonsets/${name}`, clusterId)
    const data = await this.client.basicPut<DaemonSet>({
      url,
      body,
      options: {
        clsName: this.getClassName(),
        mthdName: 'updateDaemonSet',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/apps/v1/namespaces/:namespace/daemonsets/:name',
    type: 'DELETE',
    params: ['clusterId', 'namespace', 'name'],
    error: { isClusterError: false, k8sResource: 'DaemonSet' },
  })
  deleteDaemonSet = async (clusterId, namespace, name) => {
    const url = getApiUrl(`apis/apps/v1/namespaces/${namespace}/daemonsets/${name}`, clusterId)
    const data = await this.client.basicDelete<DaemonSet>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'deleteDaemonSet',
      },
    })
    return this.convertResource(clusterId)(data)
  }

  @trackApiMethodMetadata({
    url: '/clusters/:clusterId/k8sapi/apis/metal3.io/v1alpha1/baremetalhosts',
    type: 'GET',
    params: ['clusterId'],
    error: { isClusterError: false, k8sResource: 'BareMetalHosts' },
  })
  getBareMetalHosts = async (clusterId) => {
    const url = getApiUrl(`apis/metal3.io/v1alpha1/baremetalhosts`, clusterId)
    const data = await this.client.basicGet<any>({
      url,
      options: {
        clsName: this.getClassName(),
        mthdName: 'getBareMetalHosts',
      },
    })
    return data.items.map(this.convertResource(clusterId))
  }
}

export default Qbert
